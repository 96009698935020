import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LandingLogo from "../components/landing-logo"

const IndexPage = () => (
  <>
    <Helmet
      bodyAttributes={{
        class: "landing-bg",
      }}
    />
    <Layout>
      <SEO title="Home" />
      <div className="row">
        <div className="col s12 center-align">
          <LandingLogo />
        </div>
        <div className="col s12 center-align">
          <Link to="/about">
            {" "}
            <button className="landing-btn">Enter</button>
          </Link>
        </div>
      </div>
    </Layout>
  </>
)

export default IndexPage
