import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const NavLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "IE_logo_transparent.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      className="landing-logo"
      style={{ width: "800px", marginLeft: "auto", marginRight: "auto"}}
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Imprint Entertainment"
      loading="eager"
    />
  )
}

export default NavLogo
